import classes from "./NotFound.module.scss";

export const NotFound = () => {
  return (
    <div className={classes.NotFound}>
      <h1 className={classes.NotFound__Title}>
        <span className={classes.NotFound__Title__Span}>404</span>
        <br /> Not Found
      </h1>
    </div>
  );
};
