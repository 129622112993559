import { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "../Menu/Menu";
import logotype from "../../img/logotype.svg";
import classes from "./Header.module.scss";
import { ScrollContext } from "../../ScrollContext";

export const Header = ({ innerRef }) => {
  const [menuActive, setMenuActive] = useState(false);

  const headWrapper = useRef(null);
  const burgerBtn = useRef(null);

  const { scroll, setScroll } = useContext(ScrollContext);

  const handleClick = (e) => {
    if (e.currentTarget.classList.contains(classes.open)) {
      e.currentTarget.classList.remove(classes.open);
      headWrapper.current.classList.remove(classes.fixed);
    } else {
      e.currentTarget.classList.add(classes.open);
      headWrapper.current.classList.add(classes.fixed);
    }
    setMenuActive(!menuActive);
  };

  return (
    <header>
      <Menu
        active={menuActive}
        setActive={setMenuActive}
        innerRef={innerRef}
        headWrapper={headWrapper}
        burgerBtn={burgerBtn}
        fixed={classes.fixed}
        open={classes.open}
      />
      <div ref={headWrapper} className={classes.Head__Wrapper}>
        <div>
          {window.location.pathname === "/" ? (
            <Link
              to="/"
              onClick={() => {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
                setMenuActive(false);
                burgerBtn.current.classList.remove(classes.open);
              }}
              className={classes.Header__Logotype}
            >
              <img
                className={classes.Header__Logotype__Image}
                src={logotype}
                alt="logotype"
              />
              <span className={classes.Header__Logotype__Name}>atlantida</span>
            </Link>
          ) : (
            <Link to="/" className={classes.Header__Logotype}>
              <img
                className={classes.Header__Logotype__Image}
                src={logotype}
                alt="logotype"
              />
              <span className={classes.Header__Logotype__Name}>atlantida</span>
            </Link>
          )}
        </div>
        <nav>
          <div className={classes.Nav__Wrapper}>
            <div className={classes.Nav__List}>
              <div className={classes.Nav__Item}>
                <div className={classes.Nav__Item__Btn}>
                  <p>Ecosystem</p>
                </div>
                <ul className={classes.Nav__Item__List}>
                  <li>
                    {window.location.pathname === "/" ? (
                      <Link
                        onClick={() => {
                          innerRef.current.scrollIntoView({
                            behavior: "smooth",
                          });
                        }}
                        to="/"
                      >
                        White paper
                      </Link>
                    ) : (
                      <Link to="/">
                        <div onClick={() => setScroll(!scroll)}>White paper</div>
                      </Link>
                    )}
                  </li>
                  <li>
                    {window.location.pathname === "/services" ? (
                      <Link
                        to="/services"
                        onClick={() =>
                          window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          })
                        }
                      >
                        Services
                      </Link>
                    ) : (
                      <Link to="/services">Services</Link>
                    )}
                  </li>
                </ul>
              </div>
              <div className={classes.Nav__Item}>
                {window.location.pathname === "/coin" ? (
                  <Link
                    to="/coin"
                    onClick={() =>
                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      })
                    }
                  >
                    Coin
                  </Link>
                ) : (
                  <Link to="/coin">Coin</Link>
                )}
              </div>
              <div className={classes.Nav__Item}>
                <a
                  href="https://go.atlantida.dev/to/github"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </a>
              </div>
            </div>
          </div>
          <div
            ref={burgerBtn}
            onClick={handleClick}
            className={classes.Nav__Burger__Btn}
          >
            <span />
          </div>
        </nav>
      </div>
    </header>
  );
};
