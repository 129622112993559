import { useRef } from "react";
import classes from "./NotFoundPage.module.scss";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { NotFound } from "../components/NotFound/NotFound";

export const NotFoundPage = () => {
  const whitePaper = useRef(null);

  return (
    <div className={classes.NotFoundPage__Wrapper}>
      <Header innerRef={whitePaper} />
      <main>
        <div className="container">
            <NotFound />
        </div>
      </main>
      <Footer innerRef={whitePaper} />
    </div>
  );
};
