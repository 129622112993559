import { useEffect, useRef, useContext } from "react";
import { AtlantidaCoin } from "../components/AtlantidaCoin/AtlantidaCoin";
import { Footer } from "../components/Footer/Footer";
import { Greeting } from "../components/Greeting/Greeting";
import { Header } from "../components/Header/Header";
import { KeyComponents } from "../components/KeyComponents/KeyComponents";
import { WhitePaper } from "../components/WhitePaper/WhitePaper";
import { ScrollContext } from "../ScrollContext";

export const HomePage = () => {
  const whitePaper = useRef(null);
  const coinChannels = useRef(null);
  const useCases = useRef(null);

  const {scroll, setScroll} = useContext(ScrollContext)

  useEffect(() => {
    if (scroll === false) {
      window.scroll({top:0, left:0,behavior:"smooth"})
    }
    else {
      whitePaper.current.scrollIntoView({
        behavior: "smooth",
      });
      setScroll(!scroll)
    }
  },[])

  return (
    <>
      <Header innerRef={whitePaper} />
      <main>
        <div className="container">
          <Greeting/>
          <WhitePaper ref={whitePaper} />
          <AtlantidaCoin innerRef={useCases}/>
          <KeyComponents />
        </div>
      </main>
      <Footer innerRef={[whitePaper, coinChannels]} />
    </>
  );
};
