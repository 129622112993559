import classes from "./CoinChannels.module.scss";
import russia from "../../img/flags/russia.png";
import finland from "../../img/flags/finland.png";
import uk from "../../img/flags/uk.png";
import channel from "../../img/channel.svg";
import { forwardRef } from "react";

export const CoinChannels = forwardRef((props, ref) => {
  let channelsArray = [
    {
      name: "Atlantida Official",
      description: "English-speaking Atlantida community",
      image: `${channel}`,
      bckImg: `${uk}`,
      link: "https://go.atlantida.dev/to/telegram",
    },
    {
      name: "Atlantida RUS",
      description: "Русскоговорящее сообщество Atlantida",
      image: `${channel}`,
      bckImg: `${russia}`,
      link: "https://go.atlantida.dev/to/telegram_rus",
    },
    {
      name: "Atlantida FIN",
      description: "Finnish-speaking Atlantida community",
      image: `${channel}`,
      bckImg: `${finland}`,
      link: "https://go.atlantida.dev/to/telegram_fin",
    },
  ];

  return (
    <section>
      <div className={classes.CoinChannels__Wrapper}>
        <h2 ref={ref}>
          <span>Coin</span> Channels
        </h2>
        <div className={classes.CoinChannels__Channels}>
          {channelsArray.map((channel, index) => {
            return (
              <a
                className={classes.Channel}
                key={index}
                href={channel.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.Channel__Image}
                  src={channel.image}
                  alt={channel.name}
                />
                <img
                  className={classes.Channel__Background__Image}
                  src={channel.bckImg}
                  alt={channel.name}
                />
                <div className={classes.Channel__Text__Wrapper}>
                  <p className={classes.Channel__Name}>{channel.name}</p>
                  <p className={classes.Channel__Description}>
                    {channel.description}
                  </p>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
});
