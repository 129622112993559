import classes from "./AtlantidaCoin.module.scss";
import chest from "../../img/chest.svg";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ScrollContext } from "../../ScrollContext";

export const AtlantidaCoin = ({ innerRef }) => {
  const { scroll, setScroll } = useContext(ScrollContext);
  return (
    <section className={classes.AtlantidaCoin}>
      <img className={classes.AtlantidaCoin__Image} src={chest} alt="chest" />
      <div className={classes.AtlantidaCoin__Wrapper}>
        <h2>
          Atlantida <span>Coin</span>
        </h2>
        <p className={classes.AtlantidaCoin__Information}>
          Cryptocurrency of Atlantida Ecosystem.
        </p>
        <div className={classes.AtlantidaCoin__Wrapper__Cryptocurrency}>
          <div className={classes.tip}>
            <Link
              className={classes.active}
              to="/coin"
              data-tooltip="Follow to the Coin page"
            >
              <svg
                className={classes.Cryptocurrency__Icon}
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="atlantidaCoin"
                x="0px"
                y="0px"
                viewBox="0 0 2000 2000"
              >
                <g>
                  <path
                    style={{ fill: "transparent" }}
                    className="st0"
                    d="M1000,1975c-131.6,0-259.3-25.8-379.5-76.6c-116.1-49.1-220.4-119.4-309.9-209   c-89.6-89.6-159.9-193.8-209-309.9C50.8,1259.3,25,1131.6,25,1000c0-131.6,25.8-259.3,76.6-379.5c49.1-116.1,119.4-220.4,209-309.9   s193.8-159.9,309.9-209C740.7,50.8,868.4,25,1000,25c131.6,0,259.3,25.8,379.5,76.6c116.1,49.1,220.4,119.4,309.9,209   c89.5,89.6,159.9,193.8,209,309.9c50.8,120.2,76.6,247.9,76.6,379.5c0,131.6-25.8,259.3-76.6,379.5   c-49.1,116.1-119.4,220.4-209,309.9c-89.6,89.5-193.8,159.9-309.9,209C1259.3,1949.2,1131.6,1975,1000,1975z"
                  />
                  <path d="M1000,50c64.5,0,128.9,6.5,191.5,19.3c61,12.5,120.9,31.1,178.3,55.3c56.3,23.8,110.6,53.3,161.4,87.6   c50.3,34,97.6,73,140.6,116c43,43,82,90.3,116,140.6c34.3,50.8,63.8,105.1,87.6,161.4c24.2,57.3,42.9,117.3,55.3,178.3   c12.8,62.6,19.3,127,19.3,191.5s-6.5,128.9-19.3,191.5c-12.5,61-31.1,120.9-55.3,178.3c-23.8,56.3-53.3,110.6-87.6,161.4   c-34,50.3-73,97.6-116,140.6c-43,43-90.3,82-140.6,116c-50.8,34.3-105.1,63.8-161.4,87.6c-57.3,24.2-117.3,42.9-178.3,55.3   c-62.6,12.8-127,19.3-191.5,19.3s-128.9-6.5-191.5-19.3c-61-12.5-120.9-31.1-178.3-55.3c-56.3-23.8-110.6-53.3-161.4-87.6   c-50.3-34-97.6-73-140.6-116c-43-43-82-90.3-116-140.6c-34.3-50.8-63.8-105.1-87.6-161.4c-24.2-57.3-42.9-117.3-55.3-178.3   C56.5,1128.9,50,1064.5,50,1000s6.5-128.9,19.3-191.5c12.5-61,31.1-120.9,55.3-178.3c23.8-56.3,53.3-110.6,87.6-161.4   c34-50.3,73-97.6,116-140.6c43-43,90.3-82,140.6-116c50.8-34.3,105.1-63.8,161.4-87.6c57.3-24.2,117.3-42.9,178.3-55.3   C871.1,56.5,935.5,50,1000,50 M1000,0C447.7,0,0,447.7,0,1000s447.7,1000,1000,1000s1000-447.7,1000-1000S1552.3,0,1000,0L1000,0z" />
                </g>
                <g>
                  <g>
                    <path d="M1000.7,122.8c-484.8,0-877.9,393-877.9,877.9s393,877.9,877.9,877.9s877.9-393,877.9-877.9S1485.5,122.8,1000.7,122.8z     M1346,1402.7h-78.9c-11.3,0-21.3-7.2-25-17.8l-55.4-161.1c-3.7-10.7-13.7-17.8-25-17.8H837.4c-11.3,0-21.3,7.2-25,17.8    l-55.4,161.1c-3.7,10.7-13.7,17.8-25,17.8h-78.9c-18.4,0-31.2-18.3-24.8-35.6l311.2-844.2c3.8-10.4,13.7-17.3,24.8-17.3h70.5    c11.1,0,21,6.9,24.8,17.3l311.2,844.2C1377.2,1384.3,1364.4,1402.7,1346,1402.7z" />
                  </g>
                  <g>
                    <path d="M1024.6,751.7c-8.2-23.8-41.8-23.8-50,0l-102.9,299.4c-5.9,17.2,6.9,35,25,35h205.7c18.1,0,30.9-17.9,25-35L1024.6,751.7z    " />
                  </g>
                </g>
              </svg>
              <p>Coin</p>
            </Link>
            <span className={classes.tip__span}>Follow to the Coin page</span>
          </div>
          <div className={classes.tip}>
            <Link
              className={classes.active}
              to="/coin"
              onClick={() => setScroll(!scroll)}
              data-tooltip="Explore Coin use cases"
            >
              <svg
                className={classes.Cryptocurrency__Icon}
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                {" "}
                <path d="M 19 3 C 17.346 3 16 4.346 16 6 C 16 6.4617584 16.113553 6.8939944 16.300781 7.2851562 L 12.585938 11 L 7.8164062 11 C 7.4021391 9.8387486 6.3016094 9 5 9 C 3.346 9 2 10.346 2 12 C 2 13.654 3.346 15 5 15 C 6.3016094 15 7.4021391 14.161251 7.8164062 13 L 12.585938 13 L 16.300781 16.714844 C 16.113553 17.106006 16 17.538242 16 18 C 16 19.654 17.346 21 19 21 C 20.654 21 22 19.654 22 18 C 22 16.346 20.654 15 19 15 C 18.538242 15 18.106006 15.113553 17.714844 15.300781 L 14.414062 12 L 17.714844 8.6992188 C 18.106006 8.8864466 18.538242 9 19 9 C 20.654 9 22 7.654 22 6 C 22 4.346 20.654 3 19 3 z" />
              </svg>
              <p>Use cases</p>
            </Link>
            <span className={classes.tip__span}>Explore Coin use cases</span>
          </div>
          <div className={classes.tip}>
            <a
              className={classes.disabled}
              href="##"
            >
              <svg
                className={classes.Cryptocurrency__Icon}
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                {" "}
                <path d="M 11.5 0 C 10.119 0 9 1.119 9 2.5 L 9 4 L 5 4 C 3.895 4 3 4.895 3 6 L 3 9 C 3 9.552 3.448 10 4 10 L 4.3574219 10 C 5.6654219 10 6.8553281 10.941188 6.9863281 12.242188 C 7.1363281 13.739187 5.966 15 4.5 15 L 4 15 C 3.448 15 3 15.448 3 16 L 3 19 C 3 20.105 3.895 21 5 21 L 8 21 C 8.552 21 9 20.552 9 20 L 9 19.642578 C 9 18.334578 9.9411875 17.144672 11.242188 17.013672 C 12.739187 16.863672 14 18.034 14 19.5 L 14 20 C 14 20.552 14.448 21 15 21 L 18 21 C 19.105 21 20 20.105 20 19 L 20 15 L 21.5 15 C 22.881 15 24 13.881 24 12.5 C 24 11.119 22.881 10 21.5 10 L 20 10 L 20 6 C 20 4.895 19.105 4 18 4 L 14 4 L 14 2.5 C 14 1.119 12.881 0 11.5 0 z" />
              </svg>
              <p className={classes.Cryptocurrency__Disabled__Name}>
                Exchanges
              </p>
            </a>
            <span className={classes.tip__span}>We are working on it</span>
          </div>
        </div>
      </div>
    </section>
  );
};
