import { forwardRef } from "react";
import classes from "./UseCases.module.scss";

export const UseCases = forwardRef((props, ref) => {
  let useCasesList = [
    {
      description: "Atlantida Strategies subscripton fee.",
    },
    {
      description: "Atlantida Analytics subscripton fee.",
    },
    {
      description: "Atlantida Autopilot subscripton fee.",
    },
    {
      description: "Payments for custom crypto index orders.",
    },
    {
      description:
        "Public evaluation and new crypto projects audition payments.",
    },
    {
      description: "One of the ways to get Atlantida Funds tokens.",
    },
  ];

  return (
    <section className={classes.UseCases}>
      <h2 ref={ref} className={classes.UseCases__Title}>
        Coin <span>Use cases</span>
      </h2>
      <p className={classes.UseCases__Information}>
        As the blockchain ecosystem expands, the coin will go beyond a mere
        payment system in the project tokenomics.
      </p>
      <ul className={classes.UseCases__List}>
        {useCasesList.map((usecase, index) => (
          <li className={classes.UseCases__List__Item} key={index}>
            <p className={classes.UseCases__List__Item__Paragraph}>
              {usecase.description}
            </p>
          </li>
        ))}
      </ul>
    </section>
  );
});
