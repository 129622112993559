import { useEffect, useRef, useContext } from "react";
import { Coin } from "../components/Coin/Coin";
import { Exchanges } from "../components/Exchanges/Exchanges";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { UseCases } from "../components/UseCases/UseCases";
import { ScrollContext } from "../ScrollContext";

export const AtlantidaCoinPage = () => {
  const whitePaper = useRef(null);
  const coinChannels = useRef(null);
  const useCases = useRef(null);

  const { scroll, setScroll } = useContext(ScrollContext);

  useEffect(() => {
    if (scroll === false) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    } else {
      useCases.current.scrollIntoView({
        behavior: "smooth",
      });
      setScroll(!scroll);
    }
  }, []);

  return (
    <>
      <Header innerRef={whitePaper} />
      <main>
        <div className="container">
          <Coin />
          <Exchanges />
          <UseCases ref={useCases} />
        </div>
      </main>
      <Footer innerRef={[whitePaper, coinChannels]} />
    </>
  );
};
