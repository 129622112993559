import classes from "./Exchanges.module.scss";

export const Exchanges = () => {
  let exchangesArray = [
    {
      link: "/services"
    },
    {
      link: "/services"
    },
    {
      link: "/services"
    },
    {
      link: "/services"
    },
    {
      link: "/services"
    },
    {
      link: "/services"
    },
  ];

  return (
    <section>
      <div className={classes.Exchanges__Wrapper}>
        <h2 className={classes.disabled}>
          Exchanges
        </h2>
        <div className={classes.Exchanges__Channels}>
          {exchangesArray.map((channel, index) => {
            return (
              <div
                className={classes.Channel + " " + classes.disabled}
                key={index}
                href={channel.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className={classes.Channel__Image + " " + classes.skeleton}
                />
                <div className={classes.Channel__Text__Wrapper}>
                  <div
                    className={classes.Channel__Name + " " + classes.skeleton}
                  ></div>
                  <div
                    className={
                      classes.Channel__Description__Upper +
                      " " +
                      classes.skeleton
                    }
                  ></div>
                  <div
                    className={
                      classes.Channel__Description__Lower +
                      " " +
                      classes.skeleton
                    }
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
