import { useContext, useEffect, useRef } from "react";
import { CoinChannels } from "../components/CoinChannels/CoinChannels";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { Banner } from "../components/Banner/Banner";
import banner from "../img/banner.svg";
import { ScrollContext } from "../ScrollContext";

export const ServicesPage = () => {
  const whitePaper = useRef(null);
  const coinChannels = useRef(null);

  const { scroll, setScroll } = useContext(ScrollContext);

  useEffect(() => {
    if (scroll === false) {
      window.scroll({top:0, left:0,behavior:"smooth"})
    }
    else {
      coinChannels.current.scrollIntoView({
        behavior: "smooth",
      });
      setScroll(!scroll)
    }
  }, []);

  return (
    <>
      <Header innerRef={whitePaper} />
      <main>
        <div className="container">
          <Banner image={banner} />
          <CoinChannels ref={coinChannels} />
        </div>
      </main>
      <Footer innerRef={[whitePaper, coinChannels]} />
    </>
  );
};
