import classes from "./Coin.module.scss";
import coin from "../../img/coin_greeting.gif";

export const Coin = () => {
  return (
    <section className={classes.Coin}>
      <div className={classes.Coin__Wrapper}>
        <h1>
          <span>Atlantida</span> Coin
        </h1>
        <p className={classes.Coin__Information}>
          This page has all the necessary information about Atlantida Coin: use cases, exchanges, etc. 
        </p>
        <p className={classes.Coin__Slogan}>
          Explore Atlantida Coin now for joyful cryptoexperience tomorrow!
        </p>
      </div>
      <img src={coin} alt="Coin" className={classes.Coin__Image}/>
    </section>
  );
};
