import { useContext } from "react";
import { Link } from "react-router-dom";
import { ScrollContext } from "../../ScrollContext";
import classes from "./Button.module.scss";

export const Button = (props) => {
  const mediaRefs = {
    Telegram: "Telegram",
    Discord: "https://go.atlantida.dev/to/discord",
    GitHub: "https://go.atlantida.dev/to/github",
  };

  let media = props.button.props.children[0].toString();

  const { scroll, setScroll } = useContext(ScrollContext);

  return mediaRefs[media] !== "Telegram" ? (
    <a className={classes.button} href={mediaRefs[media]} target="_blank" rel="noopener noreferrer">
      <button className={classes.button__main}>
        <span>{props.button}</span>
        <div className={classes.button__marquee} aria-hidden="true">
          <div className={classes.button__marquee__inner}>
            <span>{props.button}</span>
            <span>{props.button}</span>
            <span>{props.button}</span>
            <span>{props.button}</span>
          </div>
        </div>
      </button>
    </a>
  ) : (
    <div className={classes.button} onClick={() => setScroll(!scroll)}>
      <Link to="/services">
        <button className={classes.button__main}>
          <span>{props.button}</span>
          <div className={classes.button__marquee} aria-hidden="true">
            <div className={classes.button__marquee__inner}>
              <span>{props.button}</span>
              <span>{props.button}</span>
              <span>{props.button}</span>
              <span>{props.button}</span>
            </div>
          </div>
        </button>
      </Link>
    </div>
  );
};
