import "./App.css";
import { Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { ServicesPage } from "./pages/ServicesPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import {ScrollContext} from './ScrollContext'
import { useMemo, useState } from "react";
import { AtlantidaCoinPage } from "./pages/AtlantidaCoinPage";

function App() {
  const [scroll, setScroll] = useState(false)
  const value = useMemo(() => ({scroll, setScroll}), [scroll])
  
  return (
    <ScrollContext.Provider value={value}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/coin" element={<AtlantidaCoinPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ScrollContext.Provider>
  );
}

export default App;
