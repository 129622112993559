import classes from "./WhitePaper.module.scss";
import russia from "../../img/flags/russia.png";
import finland from "../../img/flags/finland.png";
import uk from "../../img/flags/uk.png";
import ukraine from "../../img/flags/ukraine.png";
import arabian from "../../img/flags/arabian.png";

import { forwardRef } from "react";

export const WhitePaper = forwardRef((props, ref) => {
  let mdLinks = {
    eng: "https://go.atlantida.dev/to/whitepaper_eng",
    ru: "https://go.atlantida.dev/to/whitepaper_rus",
    fin: "https://go.atlantida.dev/to/whitepaper_fin",
    ua: "https://github.com/atlantida-dev/atlantida-whitepaper/blob/main/translations/WHITEPAPER_UA.md",
    est: "https://github.com/atlantida-dev/atlantida-whitepaper/blob/main/translations/WHITEPAPER_EST.md",
    are: "https://github.com/atlantida-dev/atlantida-whitepaper/blob/main/translations/WHITEPAPER_ARE.md"
  }
  return (
    <section className={classes.WhitePaper}>
      <div className={classes.WhitePaper__Wrapper}>
        <h2 ref={ref}>White paper</h2>
        <p className={classes.WhitePaper__Information}>
          Atlantida technical description.
        </p>
        <div className={classes.WhitePaper__Wrapper__Languages}>
          <a
            className={classes.active}
            href={mdLinks.eng}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.Languages__Flag}
              src={uk}
              alt="uk"
              width="32px"
              heigh="32px"
            />
            <p>English</p>
          </a>
          <a
            className={classes.active}
            href={mdLinks.ru}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.Languages__Flag}
              src={russia}
              alt="ru"
              width="32px"
              heigh="32px"
            />
            <p>Русский</p>
          </a>
          <a
            className={classes.ua}
            href="##"
            data-tooltip="Ми працюємо над цим"
          >
            <img
              className={classes.Languages__Flag}
              src={ukraine}
              alt="ua"
              width="32px"
              heigh="32px"
            />
            <p>Український</p>
          </a>
          <a
            className={classes.active}
            href={mdLinks.fin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.Languages__Flag}
              src={finland}
              alt="fin"
              width="32px"
              heigh="32px"
            />
            <p>Suomi</p>
          </a>
          <a
            className={classes.disabled}
            href="##"
            data-tooltip="نحن نعمل على ذلك"
          >
            <img
              className={classes.Languages__Flag}
              src={arabian}
              alt="are"
              width="32px"
              heigh="32px"
            />
            <p>العربية</p>
          </a>
        </div>
      </div>
    </section>
  );
});
